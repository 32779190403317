import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import Modal from "../modal/modal"
import UserItem from "../user-item/user-item"
import "./send-gift.css"

const SendGiftView = (props) => {

  const [params, setParams] = useState({
    occasion: "",
    stock: {},
    amount: 0,
    bank: false,
    message: "",
    selectedBank: false,
    coupon: "",
    couponSubmitted: false,
    couponValid: false
  })

  const occasions = ["special occasion", "birthday", "graduation", "christmas", "bar/bat mitzvah", "hanukkah"]
  const amounts = [25, 50, 100, 250]

  const handleSelection = (target) => {
    setParams({...params, [target.name]: target.value})
  }

  const [user, stocks] = useSelector((state) => [state.auth.user, state.ui.stocks])
  const [banks, setBanks] = useState([])
  const [step, setStep] = useState(1)
  const [totals, setTotals] = useState({})
  const [error, setError] = useState(false)
  const stripeRef = useRef(false)

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const productsRes = await fetch("https://www.paperairplanefund.com/products.json").then((res) => res.json())
        const banks = productsRes.products.filter((p) => p.product_type === "piggy bank")

        if (banks.length > 0) {

          const banksArr = []
          banks.forEach((bank) => { 
            if (bank.variants) {
              if (!params.selectedBank) {
                setParams({...params, selectedBank: bank.variants[0].id})
              }

              bank.variants.forEach((variant) => {
                banksArr.push({
                  id: variant.id,
                  image: variant.featured_image.src,
                  title: variant.title
                })
              });
            }
          })

          setBanks(banksArr)
        }
      }
      catch (e) {
        console.log("Error fetching banks: " + e)
      }
    }

    fetchBanks()

    // Set stripe
    stripeRef.current = new window.Stripe("pk_live_51IDNMOGaxIRIxinSwrGcFVzinf89ndmuoc5yYAbbVjk3LCL3dwoYc6Pyb3Gj11em3jitwmRiHEZARXMYj1m1VcAf00uL7kuOLd")
  }, [])

  const handleContinueCheckout = () => {

    // Calculate fees
    calculateFees()
    setStep(2)
  }

  const handleStockSelection = (name) => {
    const stock = stocks.find((item) => item.name === name)
    setParams({...params, stock: {name: name, ticker: stock.ticker}})
  }

  const calculateFees = () => {
    let totalAmount = parseInt(params.amount)
    let giftFee
    let bankAmount = 0
    let fees = 3.99 // Koyn fee

    // Add bank if provided
    if (params.bank) {
      totalAmount += 49.99
      bankAmount = 49.99
    }

    totalAmount += fees;

    // Add Stripe fee
    totalAmount = ((1.029 * totalAmount) + 0.3).toFixed(2)
    giftFee = (totalAmount - params.amount - bankAmount).toFixed(2)
    setTotals({
      fees: giftFee,
      bank: bankAmount,
      total: totalAmount
    })
  }

  const handleCheckout = () => {
    const baseUrl = "https://d187o1jh2b2bbs.cloudfront.net"
    const apiUrl = baseUrl + "/api/payments/create-session"

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        stock: params.stock.name,
        amount: totals.total,
        user_id: user.user_id,
        piggy_bank: params.selectedBank,
        returnUrl: window.location.href
      })
    })
    .then(function(response) {
      return response.json()
    })
    .then(function(session) {

      // Store session in sessionStorage
      window.sessionStorage.setItem("pafCheckoutSession", JSON.stringify({
        ...params, 
        sessionId: session.id,
        toKidId: props.kid.user_id
      }))

      return stripeRef.current.redirectToCheckout({sessionId: session.id})
    })
    .then(function(result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, you should display the localized error message to your
      // customer using `error.message`.
      if (result.error) {
        setError(result.error)
      }
    })
    .catch(function(error) {
      setError(error)
    })
  }

  const doCoupon = () => {
    const couponCode = params.coupon.trim().toLowerCase()

    // Add coupon if valid
    if (couponCode && couponCode === "beta102021") {
      setParams({...params, couponValid: true, couponSubmitted: true})
      setTotals({...totals, total: 0.50})
    }
    else {
      setParams({...params, couponValid: false, couponSubmitted: true})
    }
  }

  const children = (
    <div className="send-gift-view">
      { step === 1 &&
        <>
          <ul className="paf-list">
            <li><UserItem user={props.kid} /></li>
          </ul>
          <div className="gift-section">
            <h4>select occasion</h4>
            <select name="occasion" onChange={(e) => handleSelection(e.target)}>
              <option value="">please select</option>
              {
                occasions.map((item, i) => <option key={i} value={item}>{item}</option>)
              }
            </select>
          </div>
          <div className="gift-section">
            <h4>select stock</h4>
            <select name="stock" onChange={(e) => handleStockSelection(e.target.value)}>
              <option value="">please select</option>
              {
                stocks && stocks.map((item) => <option key={item.ticker} value={item.name}>{item.name}</option>)
              }
            </select>
          </div>
          <div className="gift-section">
            <h4>select amount</h4>
            <select name="amount" onChange={(e) => handleSelection(e.target)}>
              <option value="">please select</option>
              {
                amounts.map((item, i) => <option key={i} value={item}>${item}</option>)
              }
            </select>
          </div>
          <div className="gift-section">
            <input type="checkbox" value={params.bank} onChange={() => setParams({...params, bank: !params.bank})} /> add piggy bank to cart
          </div>
          { params.bank &&
            <div className="gift-section">
              <h4>select a piggy bank</h4>
              <ul>
                {
                  banks.map((item) => {
                    return (
                      <li key={item.id} onClick={() => setParams({...params, selectedBank: item.id})} className={params.selectedBank === item.id ? "selected" : ""}><img src={item.image} alt={item.title} /><h3>{item.title}</h3></li>
                    )
                  })
                }
              </ul>
            </div>
          }
          <div className="gift-section">
            <h4>add a message</h4>
            <textarea name="message" value={params.message} onChange={(e) => handleSelection(e.target)} />
          </div>
          <button className="primary-btn" onClick={handleContinueCheckout} disabled={!params.occasion || !params.stock || !params.amount}>Continue</button>
        </>
      }
      { step === 2 &&
        <>
         <div className="amount-section">
            <h4>stock amount</h4>
            <h5>${params.amount}</h5>
          </div>
          <div className="amount-section">
            <h4>* gifting fee</h4>
            <h5>${totals.fees}</h5>
          </div>
          { totals.bank > 0 &&
            <div className="amount-section">
              <h4>piggy bank</h4>
              <h5>${totals.bank}</h5>
            </div>
          }
          <div className="amount-section">
            <h4>shipping</h4>
            <h5>$0.00</h5>
          </div>
          <div className="amount-section">
            <h4>coupon code</h4>
            <input type="text" name="coupon" value={params.coupon} onChange={(e) => handleSelection(e.target)} />
            <button className="secondary-btn" disabled={!params.coupon} onClick={doCoupon}>Save</button>
          </div>
          {params.couponSubmitted &&
            <div className="amount-section">
              <h4>coupon</h4>
              <h5>{params.couponValid ? "Beta Tester Flat Fee" : "invalid code"}</h5>
            </div>
          }
          <div className="amount-section">
            <h4>TOTAL</h4>
            <h5>${totals.total}</h5>
          </div>
          <div className="amount-section last">
            <h6>* 2.9% + $0.30 credit card processing fee + $3.99 gifting fee. This ensures the recipient won't have to pay anything when they redeem.</h6>
          </div>
          { error && <div className="error">{error}</div> }
          <button id="checkout-btn" className="primary-btn" onClick={handleCheckout}>Checkout</button>
        </>
      }
    </div>
  )

  return (
    <Modal title="Send a gift" className="min-height" close={props.close} children={children} />
  )
}

export default SendGiftView