import { useState } from "react"
import "./onboarding.css"
import Copy from "../../utils/copy";
import { useSelector } from "react-redux";

const Onboarding = (props) => {

  const [currentIndex, setCurrentIndex] = useState(0)
  const copy = useSelector((state) => state.ui.copy)

  const paths = [
    {
      header: Copy.getCopyForId(copy, 19).replace("{name}", props.user.firstname),
      body: Copy.getCopyForId(copy, 20),
      position: {
        top: "50%",
        left: "50%"
      }
    },
    {
      header: Copy.getCopyForId(copy, 21),
      body: Copy.getCopyForId(copy, 22),
      position: {
        top: "35%",
        left: "40%"
      }
    },
    {
      header: Copy.getCopyForId(copy, 23),
      body: Copy.getCopyForId(copy, 24),
      position: {
        top: "35%",
        left: "50.5%"
      }
    },
    {
      header: Copy.getCopyForId(copy, 25),
      body: Copy.getCopyForId(copy, 26),
      position: {
        top: "23%",
        left: "75%"
      }
    },
    {
      header: Copy.getCopyForId(copy, 27),
      body: Copy.getCopyForId(copy, 28),
      position: {
        top: "50%",
        left: "50%"
      }
    }
  ]

  const handleNext = () => {
    if (currentIndex + 1 < paths.length) {
      setCurrentIndex(currentIndex+1)
    }
    else {
      props.close()
    }
  }

  return (
    <div className="onboarding-container-wrapper">
      <div className="onboarding-container" style={{top: paths[currentIndex].position.top, left: paths[currentIndex].position.left}}>
        <div className="onboarding-container-header">
          <h2>{paths[currentIndex].header}</h2>
          <button className="close-btn" onClick={props.close}><span className="icon-x"></span></button>
        </div>
        <div className="onboarding-container-content">
          <div dangerouslySetInnerHTML={{__html: paths[currentIndex].body}} />
          <button className="next-btn" onClick={handleNext}>{currentIndex === paths.length -1 ? "Start Gifting!" : "Next"}</button>
        </div>
      </div>
    </div>
  )
}

export default Onboarding