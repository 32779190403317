import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { sendGiftFlow } from "../../redux/uiSlice"
import "./user-item.css"

const UserItem = (props) => {
  const [user, setUser] = useState(false)
  const [photoHash, setPhotoHash] = useState("1")
  const dispatch = useDispatch()

  const handleSendGift = (parent, kid) => {
    dispatch(sendGiftFlow({show: true, parent: parent, kid: kid}))
  }

  useEffect(() => {
    setUser(props.user)
    setPhotoHash(new Date().valueOf().toString())
  }, [props.user]) // eslint-disable-line

  if (!user) {
    return null
  }

  const handlePhotoChange = (e) => {
    props.onKidPhotoChange(user.user_id, e.target.files[0])
    e.target.files = null
  }

  return (
    <div className="user-item-container">
      <div className={props.indent ? "user-item indent" : "user-item"}>
        <div className="paf-photo">
          { props.showPhotoUpload && <label htmlFor={`photo-${user.user_id}`}><span>update</span></label> }
          { props.showPhotoUpload && 
            <div className="photo-upload-input">
              <input type="file" id={`photo-${user.user_id}`} accept=".png,.jpg" onChange={(e) => handlePhotoChange(e) } />
            </div>
          }
          { user.photo ? <img src={`${user.photo}?v=${photoHash}`} alt={user.username} /> : <div className="paf-avatar">{user.firstname[0]}{user.lastname[0]}</div> }
        </div>
        <h3>{user.firstname} {user.lastname}<br /><i>@{user.username}</i></h3>
        { props.showActions &&
          <div className="pending-buttons">
            { props.item.sent_request && "Request Sent"}
            { !props.item.sent_request && <button className="reject-btn" onClick={() => props.onUpdateFriend(props.item.connection_id, "reject")}>Reject</button> }
            { !props.item.sent_request && <button className="accept-btn" onClick={() => props.onUpdateFriend(props.item.connection_id, "accept")}>Accept</button> }
          </div>
        }
        { props.showDelete &&
          <div className="pending-buttons">
            <button className="reject-btn" onClick={() => props.onDelete(user.user_id)}>Remove</button>
          </div>
        }
        {
          props.showAdd &&
          <button onClick={() => props.onAddFriend(props.item.user.user_id)} disabled={props.addDisabled}>{props.addDisabled ? "Requested" : "Add friend"}</button>
        }
        {
          props.showGift &&
          <button className="primary-text-btn" onClick={() => handleSendGift(props.parent, user)}>send gift</button>
        }
      </div>
      {/* Kids */}
      { user.kids && user.kids.length > 0 &&
        user.kids.map((kid) => {
          return (
            <UserItem key={kid.kid_id} parent={user} user={kid.user} indent={true} showGift={props.isFriend} />
          )
        })
      }
    </div>
  )
}

export default UserItem