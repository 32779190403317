import "./feed.css"
import logo from "../../logo.svg"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { showAppWall, showSpinner, sendGiftFlow } from "../../redux/uiSlice"
import moment from "moment"
import Search from "../search/search"
import UserItem from "../user-item/user-item"
import ProfileModal from "../profile/profile"
import Onboarding from "../onboarding/onboarding"
import SendGiftView from "../send-gift/send-gift"

const Feed = (props) => {

  const [user, giftFlowParams] = useSelector((state) => [state.auth.user, state.ui.sendGiftFlow])
  const [friends, setFriends] = useState([])
  const [pending, setPending] = useState([])
  const [events, setEvents] = useState([])
  const [myEvents, setMyEvents] = useState([])
  const [tab, setTab] = useState("friends")
  const [showSearch, setShowSearch] = useState(false)
  const [showProfile, setShowProfile] = useState(false)
  const [showOnboarding, setShowOnboarding] = useState(false)
  const [giftFlow, setGiftFlow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const dispatch = useDispatch()

  // Fetch friends
  useEffect(() => {

    if (user && friends.length < 1) {
      fetchFriends()
    }
    
  }, [user]) // eslint-disable-line

  // Handle send gift flow
  useEffect(() => {

    if (giftFlowParams !== giftFlow) {
      console.log("Send gift state change")
      console.log(giftFlowParams)
      setGiftFlow(giftFlowParams)
    }

  }, [giftFlowParams]) // eslint-disable-line

  // Check for a gift that was sent
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.get("success") && queryParams.get("session_id")) {
      setTimeout(() => dispatch(showSpinner(true)), 500)
      const sessionId = queryParams.get("session_id")

      // Complete gift if we have a session in storage
      const storage = sessionStorage.getItem("pafCheckoutSession");
      if (storage) {
        const json = JSON.parse(storage)
        console.log(json)
        if (json.sessionId === sessionId) {
          // Complete
          setTimeout(() => completeCheckout(json), 1000)
        }
        else {
          setTimeout(() => dispatch(showSpinner(false)), 600)
        }
      }
      else {
        setTimeout(() => dispatch(showSpinner(false)), 600)
      }
    }
    else if (queryParams.get("success")) {
      setShowSuccess(true)
    }
  }, [])

  const completeCheckout = (params) => {
    const baseUrl = "https://d187o1jh2b2bbs.cloudfront.net"
    const apiUrl = baseUrl + "/api/payments/complete-session"
  
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "authorization": "Bearer " + user.token
      },
      body: JSON.stringify({
        stock: params.stock,
        amount: params.amount,
        occasion: params.occasion,
        include_piggy_bank: params.bank,
        to_user_id: "",
        to_kid_id: params.toKidId,
        piggy_bank: params.selectedBank,
        message: params.message
      })
    })
    .then((res) => res.json())
    .then((res) => {

      // Reload page
      window.location.href = window.location.pathname + "?success=true"
    })
    .catch((e) => {
      console.log(e);
      alert("We had trouble processing your gift, please try again.")
      setTimeout(() => dispatch(showSpinner(false)), 600)
    })
  }

  const fetchFriends = async () => {
    dispatch(showSpinner(true))
    const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/friends", {
      headers: {
        "authorization": "Bearer " + user.token
      }
    }).then((res) => res.json())

    console.log(res)
    setFriends(res.friends.sort((a, b) => a.user.firstname.toLowerCase() > b.user.firstname.toLowerCase() ? 1 : -1))
    setPending(res.pending.sort((a, b) => a.user.firstname.toLowerCase() > b.user.firstname.toLowerCase() ? 1 : -1))
    dispatch(showSpinner(false))
  }

  const fetchEvents = async () => {
    dispatch(showSpinner(true))
    const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/events", {
      headers: {
        "authorization": "Bearer " + user.token
      }
    }).then((res) => res.json())

    const today = moment()
    setEvents(res.friends_events.filter((item) => moment(item.date).isAfter(today)).sort((a, b) => a.date > b.date ? -1 : 1))
    setMyEvents(res.my_events.filter((item) => moment(item.date).isAfter(today)).sort((a, b) => a.date > b.date ? -1 : 1))
    console.log(res)
    dispatch(showSpinner(false))
  }

  const handleUpdateFriendship = async (friendshipId, action) => {
    if (!window.confirm("Are you sure you would like to update this connection? This action cannot be changed later.")) {
      return
    }

    dispatch(showSpinner(true))
    const res = await fetch(`https://d187o1jh2b2bbs.cloudfront.net/api/friends/${friendshipId}/${action}`, {
      method: "PATCH",
      headers: {
        "authorization": "Bearer " + user.token
      }
    }).then((res) => res.json())
    
    dispatch(showSpinner(false))
    if (res.success) {
      // update friends
      fetchFriends()
    }
  }

  const selectTab = (toTab) => {
    if (tab === toTab) {
      return
    }

    if (toTab === "friends") {
      fetchFriends()
    }
    else if (toTab === "events") {
      fetchEvents()
    }

    setTab(toTab)
  }

  const handleCloseSearch = (shouldReload) => {
    setShowSearch(false)

    if (shouldReload) {
      fetchFriends()
    }
  }

  const handleShowWall = () => {
    dispatch(showAppWall(true))
  }

  const handleCloseGift = () => {
    dispatch(sendGiftFlow({show: false, parent: false, kid: false}))
  }

  const closeSuccess = () => {
    setShowSuccess(false)
    window.sessionStorage.removeItem("pafCheckoutSession")
  }

  return (
    <div className="paf-feed">
      { giftFlow && giftFlow.show && giftFlow.kid && <SendGiftView close={handleCloseGift} kid={giftFlow.kid} /> }
      { showSearch && tab === "friends" && <Search friends={[...friends, ...pending]} close={handleCloseSearch} /> }
      { showProfile && <ProfileModal close={() => setShowProfile(false)} /> }
      { showOnboarding && <Onboarding user={user} close={() => setShowOnboarding(false)} /> }
      { showSuccess && <div className="success-toast"><h1>Your gift has been sent successfully!</h1><button onClick={closeSuccess}>Done</button></div> }
      <div className="paf-feed-header">
        <img src={logo} alt="logo" />
        <h2><strong>paper</strong>airplane<strong>fund</strong></h2>
        <button className="welcome-btn" onClick={() => setShowOnboarding(true) }>Welcome!</button>
        <div className="paf-user">
          <div className="paf-username"><button onClick={()=> setShowProfile(true)}><span className="icon-user"></span> {user && user.username}</button></div>
        </div>
      </div>
      <ul className="paf-feed-menu">
        <li onClick={() => selectTab("friends")} className={tab === "friends" ? "selected" : ""}><span className="icon-user"></span> My Friends</li>
        <li onClick={() => selectTab("events")} className={tab === "events" ? "selected" : ""}><span className="icon-calendar"></span> Upcoming Events</li>
        { tab === "friends" && <li className="search" onClick={() => setShowSearch(true)}><span className="icon-filter"></span> Search</li> }
        { tab === "events" && <li className="search" onClick={handleShowWall}><span className="icon-plus"></span> Create</li> }
      </ul>
      { tab === "friends" && pending.length > 0 &&
        <>
          <h2>requests</h2>
          <ul className="paf-list">
            { 
              pending.map((item) => {
                return (
                  <li key={item.connection_id}>
                    <UserItem user={item.user} item={item} onUpdateFriend={ handleUpdateFriendship } showActions={true} />
                  </li>
                )
              })
            }
          </ul>
        </>
      }
      { tab === "friends" && friends.length > 0 &&
        <>
          <h2>friends</h2>
        </>
      }
      { tab === "events" && myEvents.length > 0 &&
        <>
          <h2>my events</h2>
        </>
      }
      { tab === "events" && myEvents && 
        <ul className="paf-list">
          {
            myEvents.map((item) => {
              return (
                <li key={item.event_id}>
                  { item.user.photo && <img src={item.user.photo} alt={item.user.username} /> } 
                  { !item.user.photo && <div className="paf-avatar">{item.user.firstname[0]}{item.user.lastname[0]}</div> }
                  <h3>{item.name}<br /><i>@{item.user.username}</i></h3>
                  <h4>{moment(item.date*1000).format("MMM Do, YYYY")}</h4>
                </li>
              )
            })
          }
        </ul>
      }
      <ul className="paf-list">
        { tab === "friends" && friends.map((item) => {
          return (
            <li key={item.connection_id}>
              <UserItem user={item.user} isFriend={true} />
            </li>
          )
        })
        }
        { tab === "events" && events && events.map((item) => {
          return (
            <li key={item.event_id}>
              { item.user.photo && <img src={item.user.photo} alt={item.user.username} /> } 
              { !item.user.photo && <div className="paf-avatar">{item.user.firstname[0]}{item.user.lastname[0]}</div> }
              <h3>{item.name}<br /><i>@{item.user.username}</i></h3>
              <h4>{moment(item.date*1000).format("MMM Do, YYYY")}</h4>
            </li>
          )
        })
        }
      </ul>
      { tab === "events" && events.length < 1 && myEvents.length < 1 && <h5>You don't have any upcoming events yet!</h5> }
      { tab === "friends" && friends.length < 1 && pending.length < 1 && <h5>You don't have any friends yet!</h5> }
    </div>
  )
}

export default Feed