import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../../redux/authSlice"
import { showSpinner } from "../../redux/uiSlice"
import Copy from "../../utils/copy"

const Auth = (props) => {

  const [data, setData] = useState({email: "", password: "", firstname: "", lastname: "", username: ""})
  const [isLoggingIn, setIsLoggingin] = useState(false)
  const [authError, setAuthError] = useState(false)
  const copy = useSelector((state) => state.ui.copy)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value})
  }

  const handleSignup = async () => {
    setIsLoggingin(true)
    setAuthError(false)
    dispatch(showSpinner(true))

    // Login
    try {

      const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/auth/register", {
        method: "POST",
        headers: {
          'content-type': "application/json"
        },
        body: JSON.stringify({
          firstname: data.firstname,
          lastname: data.lastname,
          username: data.username.replace("[\W_]+", "-"),
          email: data.email.toLowerCase().trim(),
          password: await sha256(data.password)
        })
      }).then((res) => res.json())

      setIsLoggingin(false)
      dispatch(login({user: res}))
      setTimeout(() => {
        if (res.is_admin) {
          // Go to admin feed
          window.location.href = "/admin"
        }
        else {
          window.location.href = "/feed"
        }
      })
    }
    catch (e) {
      setAuthError("Uh oh. We had trouble creating your account. That username and/or email is already in use")
      setIsLoggingin(false)
      dispatch(showSpinner(false))
    }
  }

  const handleLogin = async () => {
    setIsLoggingin(true)
    setAuthError(false)
    dispatch(showSpinner(true))

    // Login
    try {

      const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/auth", {
        method: "POST",
        headers: {
          'content-type': "application/json"
        },
        body: JSON.stringify({
          email: data.email.toLowerCase().trim(),
          password: await sha256(data.password)
        })
      }).then((res) => res.json())

      setIsLoggingin(false)
      dispatch(login({user: res}))
      setTimeout(() => {
        if (res.is_admin) {
          window.location.href = "/admin"
        }
        else {
          window.location.href = "/feed"
        }
      })
    }
    catch (e) {
      setAuthError("Uh oh. We had trouble with your credentials. Please try again.")
      setIsLoggingin(false)
      dispatch(showSpinner(false))
    }
  }

  const sha256 = async (message) => {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message)                

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer))

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex
  } 

  return (
    <div className="paf-form">
      {
        props.type === "Login" ?
        <>
          <h3>Welcome back! Please enter your email and password</h3>
          <input type="email" placeholder="Email..." name="email" onChange={handleChange} value={data.email} />
          <input type="password" placeholder="Password..." name="password" onChange={handleChange} value={data.password} />
          { authError && <div className="paf-error">{authError}</div> }
          <button className="primary-btn" disabled={!data.email || !data.password || isLoggingIn} onClick={handleLogin}>Log in</button>
        </>
        :
        <>
          <h3>Already have an account? <button className="blue-text-btn" onClick={props.switch}>Log in now</button> to send a gift</h3>
          <h3>{Copy.getCopyForId(copy, 15)}</h3>
          <input type="text" placeholder="First name..." name="firstname" onChange={handleChange} value={data.firstname} />
          <input type="text" placeholder="Last name..." name="lastname" onChange={handleChange} value={data.lastname} />
          <input type="text" placeholder="Username..." name="username" onChange={handleChange} value={data.username} />
          <input type="email" placeholder="Email..." name="email" onChange={handleChange} value={data.email} />
          <input type="password" placeholder="Password..." name="password" onChange={handleChange} value={data.password} />
          { authError && <div className="paf-error">{authError}</div> }
          <button className="primary-btn" disabled={!data.email || !data.password || !data.firstname || !data.lastname || !data.username || isLoggingIn} onClick={handleSignup}>Next</button>
        </>
      }
    </div>
  )
}

export default Auth