import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { showSpinner } from "../../redux/uiSlice"
import { logout, update } from "../../redux/authSlice"
import Modal from "../modal/modal"
import "./profile.css"
import UserItem from "../user-item/user-item"

const ProfileModal = (props) => {

  const user = useSelector((state) => state.auth.user)
  const [newPhoto, setNewPhoto] = useState(false)
  const [tab, setTab] = useState("profile")
  const [isEditing, setIsEditing] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const dispatch = useDispatch()
  const [workingUser, setWorkingUser] = useState(false)
  const [newKid, setNewKid] = useState({username: "", firstname: "", lastname: "", birthday: ""})

  useEffect(() => {
    setWorkingUser(user)

  }, []) // eslint-disable-line

  const handleNewPhoto = (e) => {
    const file = e.target.files[0]
    setNewPhoto(file)
  }

  const handleCancelUpload = () => {
    setNewPhoto(false)
  }

  const handleKidPhotoChange = async (userId, photo) => {
    let formData = new FormData()
    formData.append('file', photo)
    dispatch(showSpinner(true))

    try {
      const res = await fetch(`https://d187o1jh2b2bbs.cloudfront.net/api/auth/photo/${userId}`, {
        method: "PATCH",
        headers: {
          'authorization': `Bearer ${user.token}`
        },
        body: formData
      }).then((res) => res.json())

      dispatch(update({kids: res.kids}))
      dispatch(showSpinner(false))
    }
    catch (e) {
      alert("Uh oh. There was an error updating your photo. Please make sure it is less than 2mb and try again")
      dispatch(showSpinner(false))
    }
  }

  const handleUpload = async () => {
    let formData = new FormData()
    formData.append('file', newPhoto)
    dispatch(showSpinner(true))

    try {
      const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/auth/photo", {
        method: "PATCH",
        headers: {
          'authorization': `Bearer ${user.token}`
        },
        body: formData
      }).then((res) => res.json())

      dispatch(update({photo: res.photo}))
      dispatch(showSpinner(false))
      setNewPhoto(false)
    }
    catch (e) {
      alert("Uh oh. There was an error updating your photo. Please make sure it is less than 2mb and try again")
      dispatch(showSpinner(false))
    }
  }
  
  const handleLogout = () => {
    dispatch(logout())
    window.location.href = "/"
  }

  const handleChange = (e) => {
    setWorkingUser({...workingUser, [e.target.name]: e.target.value})
  }

  const handleKidChange = (e) => {
    setNewKid({...newKid, [e.target.name]: e.target.value})
  }

  const handleCancelChanges = () => {
    setIsEditing(false)
    setWorkingUser({...user}) 
  }

  const handleCancelKidChanges = () => {
    setIsAdding(false)
    setNewKid({username: "", firstname: "", lastname: "", birthday: ""})
  }

  const handleSaveKidChanges = async () => {
    dispatch(showSpinner(true))

    try {
      const newKidParams = {
        firstname: newKid.firstname,
        lastname: newKid.lastname,
        username: newKid.username,
        birthday: new Date(newKid.birthday).valueOf()
      }

      const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/kids", {
        method: "POST",
        headers: {
          'authorization': `Bearer ${user.token}`,
          'content-type': "application/json"
        },
        body: JSON.stringify(newKidParams)
      }).then((res) => res.json())

      dispatch(update({kids: res.updated_user.kids}))
      dispatch(showSpinner(false))
      handleCancelKidChanges()
    }
    catch (e) {
      dispatch(showSpinner(false))
      alert("Uh oh. There was an error adding your kid. That username may be taken. Please try again")
    }
  }

  const handleSaveChanges = async () => {
    dispatch(showSpinner(true))

    try {
      await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/auth/profile", {
        method: "PUT",
        headers: {
          'authorization': `Bearer ${user.token}`,
          'content-type': "application/json"
        },
        body: JSON.stringify({
          update: {
            firstname: workingUser.firstname,
            lastname: workingUser.lastname
          }
        })
      })

      dispatch(update({firstname: workingUser.firstname, lastname:  workingUser.lastname}))
      dispatch(showSpinner(false))
      setIsEditing(false)
    }
    catch (e) {
      dispatch(showSpinner(false))
      alert("Uh oh. There was an error updating your profile. Please try again")
    }
  }

  const handleDeleteKid = async (kidId) => {
    if (!window.confirm("Are you sure? This action cannot be reversed")) {
      return
    }

    dispatch(showSpinner(true))

    try {
      const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/kids/" + kidId, {
        method: "DELETE",
        headers: {
          'authorization': `Bearer ${user.token}`,
          'content-type': "application/json"
        }
      }).then((res) => res.json())
      console.log(res.kids)
      dispatch(update({kids: [...res.kids]}))
      dispatch(showSpinner(false))
    }
    catch (e) {
      dispatch(showSpinner(false))
      alert("Uh oh. There was an error removing your kid. Please try again")
    }
  }

  const children = (
    <div className="settings-content">
      <ul className="settings-menu">
        <li className={tab === "profile" ? "selected" : ""} onClick={() => setTab("profile")}>My Profile</li>
        { !workingUser.is_admin && <li className={tab === "kids" ? "selected" : ""} onClick={()=> setTab("kids")}>My Kids</li> }
      </ul>
      { tab === "profile" ? 
        <div className="profile-tab">
          <div className="main-profile">
            <div className="image-section">
              { !newPhoto && <div className="profile-image" style={{backgroundImage: `url(${user.photo ? user.photo + "?v=" + new Date().valueOf() : ""})`}}><label htmlFor="photo"><span>update</span></label>{!user.photo && `${user.firstname[0]}${user.lastname[0]}`}</div> }
              { !newPhoto ?
                <div className="photo-upload-input">
                  <input type="file" id="photo" accept=".png,.jpg" onChange={handleNewPhoto} />
                  <i>photos should be less than 2mb</i>
                </div>
                :
                <div>
                  <strong>{newPhoto.name}</strong>
                  <div className="upload-btns">
                    <button onClick={handleCancelUpload} className="cancel-btn">Cancel</button>
                    <button className="primary-btn" onClick={handleUpload}>Upload</button>
                  </div>
                </div>
              }
            </div>
            <div className="info-section">
              <div className="form-group">
                <h3>first name</h3>
                <input type="text" disabled={!isEditing} onChange={(e) => handleChange(e)} name="firstname" placeholder="First name..." value={workingUser.firstname ? workingUser.firstname : ""} />
              </div>
              <div className="form-group">
                <h3>last name</h3>
                <input type="text" disabled={!isEditing} onChange={(e) => handleChange(e)} name="lastname" placeholder="Last name..." value={workingUser.lastname ? workingUser.lastname : ""} />
              </div>
            </div>
          </div>
        </div>
        :
          <div className="kids-tab">
            { user.kids.length < 1 && <h5>You haven't added any kids yet...</h5> }
            { user.kids.length > 0 && 
              <ul className="paf-list">
                {
                  isAdding && 
                  <li className="inline-adder">
                    <div className="inline-inputs">
                      <div className="inline-group">
                        <h4>first name</h4>
                        <input type="text" onChange={(e) => handleKidChange(e)} name="firstname" placeholder="First name..." value={newKid.firstname ? newKid.firstname : ""} />
                      </div>
                      <div className="inline-group">
                        <h4>last name</h4>
                        <input type="text"onChange={(e) => handleKidChange(e)} name="lastname" placeholder="Last name..." value={newKid.lastname ? newKid.lastname : ""} />
                      </div>
                      <div className="inline-group">
                        <h4>username</h4>
                        <input type="text" onChange={(e) => handleKidChange(e)} name="username" placeholder="Username..." value={newKid.username ? newKid.username : ""} />
                      </div>
                      <div className="inline-group">
                        <h4>birthday</h4>
                        <input type="date" onChange={(e) => handleKidChange(e)} name="birthday" placeholder="Birthday..." value={newKid.birthday ? newKid.birthday : ""} />
                      </div>
                    </div>
                    <div className="inline-buttons">
                      <button onClick={handleCancelKidChanges} className="cancel-btn cancel-changes-btn">Cancel</button>
                      <button onClick={handleSaveKidChanges} disabled={!newKid.firstname || !newKid.lastname || !newKid.birthday || !newKid.username} className="primary-btn save-btn">Save</button>
                    </div>
                  </li>
                }
                {
                  [...user.kids].sort((a, b) => a.user.firstname > b.user.firstname ? 1 : -1).map((kid) => {
                    return (
                      <li key={kid.user.user_id}><UserItem onKidPhotoChange={handleKidPhotoChange} user={kid.user} showPhotoUpload={true} onDelete={handleDeleteKid} showDelete={true} /></li>
                    )
                  })
                }
              </ul>
            }
          </div>
        }
        <div className="bottom-section">
          <button onClick={handleLogout} className="cancel-btn">Sign out</button>
          { !isAdding && tab === "kids" && <button onClick={() => setIsAdding(true)} className="primary-btn edit-btn">Add kid</button> }
          { tab === "profile" && !isEditing && <button onClick={() => setIsEditing(true)} className="primary-btn edit-btn">Edit profile</button> }
          { isEditing && <button onClick={handleCancelChanges} className="cancel-btn cancel-changes-btn">Cancel</button> }
          { isEditing && <button onClick={handleSaveChanges} className="primary-btn save-btn">Save</button> }
        </div>
    </div>
  )

  return (
    <Modal title="Settings" className="min-height" close={props.close} children={children} />
  )
}

export default ProfileModal