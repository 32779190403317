import { createSlice } from "@reduxjs/toolkit"
import Session from "../utils/session"

const initialState = {
  user: null
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user
      Session.set(action.payload.user)
    },
    logout: (state, action) => {
      state.user = null
      Session.clear()
    },
    update: (state, action) => {
      const updated = {...state.user, ...action.payload}
      state.user = updated
      Session.set(updated)
    }
  }
})

export const { login, logout, update } = authSlice.actions
export default authSlice.reducer