import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Session from "./utils/session"
import { login } from "./redux/authSlice"
import { setCopy, setStocks } from "./redux/uiSlice"
import Tour from './components/tour/tour'
import Feed from './components/feed/feed'
import Spinner from './components/spinner/spinner'
import AppWall from './components/app-wall/app-wall'
import AdminFeed from './components/feed/admin'

function App() {

  const dispatch = useDispatch()
  const showAppWall = useSelector((state) => state.ui.showAppWall)

  useEffect(() => {
    console.log("app.js: RUN EFFECT")
    let mounted = true

    // Get user's state
    const authUser = Session.get()
    if (authUser) {
      console.log("app.js: Session loaded")
      console.log(authUser);
      dispatch(login({user: authUser}))
    }

    // Fetch copy
    const fetchCopy = async () => {
      try {
        const copy = await fetch("https://api.airtable.com/v0/appfjozmZm559VmKo/COPY", {
          method: "GET",
          headers: {
            authorization: "Bearer keyuXUD66ouXu7CiG"
          }
        }).then((res) => res.json())

        if (mounted) {
          dispatch(setCopy(copy.records))
        }
      }
      catch (e) {
        console.error(e)
        throw new Error("Uh oh. We had an error loading paper airplane fund. Please try again.")
      }
    }

    // Fetch stocks
    const fetchStocks = async () => {
      try {
        const stocks = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/stocks", {
          method: "GET"
        }).then((res) => res.json())

        if (mounted) {
          const cats = stocks.stock_categories
          const flat = []
          cats.forEach((cat) => {
            cat.stocks.forEach((stock) => flat.push(stock))
          })
          dispatch(setStocks(flat.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)))
        }
      }
      catch (e) {
        console.error(e)
        throw new Error("Uh oh. We had an error loading paper airplane fund. Please try again.")
      }
    }

    fetchCopy()
    fetchStocks()
    return () => mounted = false

  }, []) // eslint-disable-line

  const protect = (section, props) => {
    console.log('app.js: Protect: ' + section)

    const authUser = Session.get()
    if (authUser) {
      switch (section) {
        case "feed": {
          return <Feed {...props} />
        }
        case "admin": {
          if (authUser.is_admin) {
            return <AdminFeed {...props} />
          }

          break;
        }
        default: {
          return <Tour />
        }
      }
    }

    return <Tour />
  }

  return (
    <div className="paf-app">
      <div className="paf-app-content">
        <Spinner />
        { showAppWall && <AppWall /> }
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Tour />} />
            <Route path="feed" element={protect("feed")} />
            <Route path="admin" element={protect("admin", true)} />
            <Route path="*" element={ <Tour /> } />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App
