import "./feed.css"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import logo from "../../logo.svg"
import ProfileModal from "../profile/profile"
import { showSpinner } from "../../redux/uiSlice"
import moment from "moment"
import UserItem from "../user-item/user-item"
import Modal from "../modal/modal"

const AdminFeed = () => {
  const user = useSelector((state) => state.auth.user)
  const [showProfile, setShowProfile] = useState(false)
  const [gifts, setGifts] = useState([])
  const [selectedTransaction, setSelectedTransaction] = useState(false)
  const dispatch = useDispatch()

  // Fetch friends
  useEffect(() => {

    if (user && user.is_admin) {
      fetchGifts()
    }
    
  }, [user]) // eslint-disable-line

  if (!user || !user.is_admin) {
    return null
  }

  const fetchGifts = async () => {
    dispatch(showSpinner(true))
    const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/gifts/admin/all", {
      headers: {
        "authorization": "Bearer " + user.token
      }
    }).then((res) => res.json())
    setGifts(res.sort((a, b) => a.date > b.date ? -1 : 1))
    dispatch(showSpinner(false))
  }

  const selectedTransactionContent = selectedTransaction && 
    (
      <div className="selected-transaction">
        <div className="selected-transaction-row">
          <h3>date: <strong>{moment(selectedTransaction.item.date*1000).format("MM/DD/YYYY")}</strong></h3>
          <h3>amount: <strong>${selectedTransaction.item.amount}</strong></h3>
        </div>
        <div className="selected-transaction-row">
          <h4>from:</h4>
          <div className="paf-list">
            <ul><li>
              <UserItem user={selectedTransaction.item.from_user[0]} />
            </li></ul>
          </div>
        </div>
        <div className="selected-transaction-row">
          <h4>to:</h4>
          <div className="paf-list">
            <h5>{selectedTransaction.extras.toUser}</h5>
            <h5>{selectedTransaction.extras.toKid}</h5>
            {selectedTransaction.item.to_kid && selectedTransaction.item.to_kid.length > 0 && selectedTransaction.item.to_kid[0].address && 
              <>              
                <h5>{selectedTransaction.item.to_kid[0].address.address_one}</h5> 
                <h5>{selectedTransaction.item.to_kid[0].address.address_two}</h5> 
                <h5>{selectedTransaction.item.to_kid[0].address.city}, {selectedTransaction.item.to_kid[0].address.state} {selectedTransaction.item.to_kid[0].address.zip}</h5> 
              </>
            }
          </div>
        </div>
        <div className="selected-transaction-row">
          <h3>include a piggy bank?: <strong>{selectedTransaction.item.include_piggy_bank ?  `Yes - ${selectedTransaction.item.piggy_bank_choice}` : "No"}</strong></h3>
          <h3>occasion: <strong>{selectedTransaction.item.occasion}</strong></h3>
        </div>
        <div className="selected-transaction-row">
          <h3>stock: <strong>{selectedTransaction.item.stock.name} ({selectedTransaction.item.stock.ticker})</strong></h3>
          <h3>message: <strong>{selectedTransaction.item.message ? selectedTransaction.item.message : "None"}</strong></h3>
        </div>
        <div className="selected-transaction-row">
          <h3>tremendous link: <strong>{selectedTransaction.item.tremendous_link}</strong></h3>
        </div>
      </div>
    )

  return (
    <div className="paf-feed">
      { showProfile && <ProfileModal close={() => setShowProfile(false)} /> }
      { selectedTransaction && <Modal title={`Transaction: ${selectedTransaction.item.gift_id}`} children={selectedTransactionContent} close={() => setSelectedTransaction(false)} /> }
      <div className="paf-feed-header">
        <img src={logo} alt="logo" />
        <h2><strong>paper</strong>airplane<strong>fund</strong></h2>
        <div className="paf-user" style={{marginLeft: "auto"}}>
          <div className="paf-username"><button onClick={()=> setShowProfile(true)}><span className="icon-user"></span> {user && user.username}</button></div>
        </div>
      </div>
      <h2>gifts feed</h2>
      <ul className="paf-gifts paf-list">
        <li className="header">
          <div className="flex small">date</div>
          <div className="flex small">amount</div>
          <div className="flex">from</div>
          <div className="flex">to</div>
          <div className="flex small">piggy bank?</div>
        </li>
        { 
          gifts.map((item) => {
            console.log(item)
            const isTest = item.tremendous_link.indexOf("testflight") > -1
            let toUser = ""
            let toKid = ""
            if (item.to_user && item.to_user.length > 0) {
              toUser = `${item.to_user[0].firstname} ${item.to_user[0].lastname}`

              if (item.to_kid && item.to_kid.length > 0) {
                toKid = `(for ${item.to_kid[0].firstname} ${item.to_kid[0].lastname})`
              }
            }
            else {
              toUser = "guest"
              toKid = `(${item.guest_email})`
            }

            return (
              <li key={item.gift_id} className={isTest ? "test" : ""} onClick={() => setSelectedTransaction({item: item, extras: {toUser: toUser, toKid: toKid}})}>
                <div className="flex small">{moment(item.date*1000).format("MM/DD/YYYY")}</div>
                <div className="flex small">${item.amount} {isTest && <span>(test)</span>}</div>
                <div className="flex"><UserItem user={item.from_user[0]} /></div>
                <div className="flex">{toUser}<br/>{toKid}</div>
                <div className="flex small">{item.include_piggy_bank ?  `Yes - ${item.piggy_bank_choice}` : "No"}</div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default AdminFeed