import logo from "../../logo.svg"
import appStore from "../../app-store.png"
import "./tour.css"
import { useSelector } from "react-redux"
import { useEffect, useRef, useState } from "react"
import Modal from "../modal/modal"
import Auth from "../auth/auth"

const Tour = () => {

  const copy = useSelector((state) => state.ui.copy).filter((item) => item.fields.Section === "Tour")
  const [showTour, setShowTour] = useState(false)
  const [tourIndex, setTourIndex] = useState(0)
  const tourCopy = useRef([])
  const tourImages = useRef({})
  const [showAuth, setShowAuth] = useState(false)

  const handleAuth = (authType) => {
    setShowAuth(authType)
  }

  const handleSwitchType = () => {
    if (showAuth === "Login") {
      setShowAuth("Register")
    }
    else {
      setShowAuth("Login")
    }
  }

  const searchExp = new RegExp(/([0-9]) - /)

  // Group copy
  useEffect(() => {
    let tour = {}
    let tourArr = []
    let tourImagesObj = {}
    if (copy.length > 0) {
      copy.forEach((item) => {
        const match = item.fields.Screen.match(searchExp)
        if (match && match.length > 1) {
          const key = "in-" + match[1]
          const type = item.fields.Screen.indexOf("Header") > -1 ? "header" : "body"
          tour[key] = {...tour[key], [type]: item.fields.Copy, index: parseInt(match[1])}
        }

        if (item.fields.Copy_ID === 29) {
          tourImagesObj[0] = item.fields.Copy
        }
        else if (item.fields.Copy_ID === 30) {
          tourImagesObj[1] = item.fields.Copy
        }
        else if (item.fields.Copy_ID === 31) {
          tourImagesObj[2] = item.fields.Copy
        }
        else if (item.fields.Copy_ID === 32) {
          tourImagesObj[3] = item.fields.Copy
        }
        else if (item.fields.Copy_ID === 33) {
          tourImagesObj[4] = item.fields.Copy
        }
      })

      if (Object.keys(tour).length > 0) {
        Object.keys(tour).forEach((key) => {
          tourArr.push(tour[key])
        })

        tourArr.sort((a, b) => a.index > b.index ? 1 : -1)
      }

      tourImages.current = tourImagesObj
      tourCopy.current = tourArr
    }

  }, [copy]) // eslint-disable-line

  const handleNext = () => {
    if (tourIndex < tourCopy.current.length - 1) {
      setTourIndex(tourIndex+1)
    }
    else {
      handleCancel()
    }
  }

  const handlePrevious = () => {
    if (tourIndex > 0) {
      setTourIndex(tourIndex-1)
    }
  }

  const handleCancel = () => {
    setShowTour(false)
    setTourIndex(0)
  }

  return (
    <div className="paf-tour">
      <img src={logo} className="app-logo" alt="logo" />
      <h2>welcome to <strong>paper</strong>airplane<strong>fund</strong></h2>
      <ul>
        <li><button className="tour-btn" disabled={copy.length < 1} onClick={ () => setShowTour(true) }>How does it work?</button></li>
        <li><button className="send-btn" onClick={ () => handleAuth('Register') }>Send a gift</button></li>
        <li><button onClick={ () => handleAuth('Login') }>Log in</button></li>
      </ul>
      { showAuth &&
        <Modal title={showAuth} close={() => setShowAuth(false)} children={<Auth switch={handleSwitchType} type={showAuth} />} />
      }
      { showTour && 
        <div className="paf-tour-container-holder">
          <div className="paf-tour-container">
            <h3><span>{ tourCopy.current[tourIndex].header }</span></h3>
            <img src={ tourImages.current[tourIndex] } alt={ tourCopy.current[tourIndex].header } />
            <p>{ tourCopy.current[tourIndex].body }</p>
            <div className="paf-tour-container-buttons">
              <div className="main-buttons">
                { tourIndex > 0 && <button onClick={ handlePrevious } className="secondary-btn">Previous</button> }
                <button className="primary-btn" onClick={ handleNext }>{ tourIndex < tourCopy.current.length - 1 ? "Next" : "Done" }</button>
              </div>
              { tourIndex < tourCopy.current.length - 1 && <button onClick={ handleCancel } className="cancel-btn">End Tour</button> }
            </div>
          </div>
        </div>
      }
      <a target="_blank" rel="noreferrer" href="https://apps.apple.com/lv/app/paper-airplane-fund/id1563921970"><img src={appStore} className="app-store-logo" alt="download on Apple app store" /></a>
    </div>
  )
}

export default Tour