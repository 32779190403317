import "./spinner.css"
import logo from "../../logo.svg"
import { useSelector } from "react-redux"

const Spinner = () => {

  const visible = useSelector((state) => state.ui.showSpinner)

  return (
    visible ?
    <div className="paf-spinner">
      <div className="paf-spinner-content">
        <img src={logo} className="app-logo" alt="logo" />
        <h3><span className="icon-airplane"></span> Loading...</h3>
      </div>
    </div> 
    : null
  )
}

export default Spinner