import { useRef } from "react"
import { useDispatch } from "react-redux"
import { showAppWall } from "../../redux/uiSlice"
import Modal from "../modal/modal"

const AppWall = () => {
  const dispatch = useDispatch()
  const wallCopy = useRef(<>To send gifts and more, download our mobile app from the App Store!<p><a rel="noreferrer" href="https://apps.apple.com/lv/app/paper-airplane-fund/id1563921970" target="_blank" className="primary-btn">Click here to learn more</a></p></>)
  
  const handleClose = () => {
    dispatch(showAppWall(false))
  }

  return <Modal title={<><strong>paper</strong>airplane<strong>fund</strong> is on the App Store!</>} children={wallCopy.current} close={handleClose}/>
}

export default AppWall