class Session {
  static get() {
    const user = window.localStorage.getItem("paf_user")
    return user ? JSON.parse(user) : false
  }

  static set(aNewUser) {
    window.localStorage.setItem("paf_user", JSON.stringify(aNewUser))
  }

  static clear() {
    window.localStorage.removeItem("paf_user")
  }
}

export default Session