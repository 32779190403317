import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Modal from "../modal/modal"
import InlineSpinner from "../inline-spinner/inline-spinner"
import { showSpinner } from "../../redux/uiSlice"
import UserItem from "../user-item/user-item"

const Search = (props) => {

  const timer = useRef(false)
  const [search, setSearch] = useState("")
  const currentUser = useSelector((state) => state.auth.user)
  const [noResults, setNoResults] = useState(false)
  const [results, setResults] = useState([])
  const friendIds = useRef([])
  const [loading, setLoading] = useState(false)
  const [addedFriends, setAddedFriends] = useState({})
  const [addError, setAddError] = useState(false)
  const dispatch = useDispatch()

  // Clear timer if it exists
  useEffect(() => {

    // Compile friend ids
    friendIds.current = props.friends.map((item) => {
      return item.user.user_id
    })

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
    }
  }, []) // eslint-disable-line

  const handleChange = (e) => {
    const term = e.target.value
    setSearch(term)

    // Clear timer if it exists
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }

    // Debounce
    timer.current = setTimeout(() => {
      doSearch(term)
    }, 500)
  }

  const doSearch = async (searchTerm) => {
    setLoading(true)
    setNoResults(false)
    setResults([])

    try {
      const res = await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/friends/search", {
        method: "POST",
        headers: {
          'content-type': "application/json",
          'authorization': `Bearer ${currentUser.token}`
        },
        body: JSON.stringify({
          search: searchTerm.toLowerCase().trim(),
        })
      }).then((res) => res.json())

      setLoading(false)
      console.log(res.users)
      const filtered = res.users.filter((user) => {
        return friendIds.current.indexOf(user.user.user_id) < 0 && user.user.user_id !== currentUser.user_id
      })

      setNoResults(filtered.length < 1)
      setResults(filtered)
    }
    catch (e) {
      setLoading(false)
      setNoResults(true)
    }
  }

  const handleAddFriend = async (userId) => {
    setAddError(false)
    dispatch(showSpinner(true))

    try {
      await fetch("https://d187o1jh2b2bbs.cloudfront.net/api/friends", {
        method: "POST",
        headers: {
          'content-type': "application/json",
          'authorization': `Bearer ${currentUser.token}`
        },
        body: JSON.stringify({
          to_user_id: userId
        })
      })

      dispatch(showSpinner(false))
      setAddedFriends({...addedFriends, [userId]: true})
    }
    catch (e) {
      dispatch(showSpinner(false))
      setAddError(true)
    }
  }

  const handleClose = () => {
    // We'll need to pass a flag to tell caller to reload if we added friends
    props.close(Object.keys(addedFriends).length > 0)
  }

  const children = (
    <div>
      <div className="paf-form full">
        <h3>Search by name, email, or username</h3>
        <input type="text" placeholder="Search..." onChange={handleChange} value={search} />
      </div>
      { noResults && <h5>Sorry, we could not find any users matching your search...</h5>}
      { addError && <h5>Sorry, we had an issue adding that user, please try again.</h5>}
      { loading && <InlineSpinner text="Searching..." />}
      { results && 
        (
          <ul className="paf-list">
          { 
            results.map((item) => {
              return (
                <li key={item.user.user_id}>
                  <UserItem user={item.user} item={item} showAdd={true} onAddFriend={handleAddFriend} addDisabled={addedFriends[item.user.user_id]} />
                </li>
              )
            })
          }
          </ul>
        )
      }
    </div>
  )

  return (
    <Modal className="min-height" title="Search users" children={children} close={handleClose} />
  )
}

export default Search