import { createSlice } from "@reduxjs/toolkit"

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    copy: [],
    stocks: [],
    showSpinner: false,
    showAppWall: false,
    sendGiftFlow: {
      show: false,
      parent: false,
      kid: false
    }
  },
  reducers: {
    setCopy: (state, action) => {
      state.copy = action.payload
    },
    setStocks: (state, action) => {
      state.stocks = action.payload
    },
    showSpinner: (state, action) => {
      state.showSpinner = action.payload
    },
    showAppWall: (state, action) => {
      state.showAppWall = action.payload
    },
    sendGiftFlow: (state, action) => {
      state.sendGiftFlow = action.payload
    }
  }
})

export const { setCopy, setStocks, showSpinner, showAppWall, sendGiftFlow } = uiSlice.actions
export default uiSlice.reducer