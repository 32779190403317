import "./modal.css"

const Modal = (props) => {
  const classNames = ["paf-modal-content"].concat((props.className ? [props.className] : []))

  return (
    <div className="paf-modal-container">
      <div className={classNames.join(" ")}>
        <div className="paf-modal-header">
          <h2>{props.title}</h2>
          <button className="close-btn" onClick={props.close}><span className="icon-x"></span></button>
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default Modal