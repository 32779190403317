import "./inline-spinner.css"

const InlineSpinner = (props) => {
  return (
    <div className="inline-spinner">
      <div className="inline-loader"></div>
      <h3>{ props.text ? props.text : "Loading..." }</h3>
    </div>
  )
}

export default InlineSpinner