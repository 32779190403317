class Copy {
  static getCopyForId(copy, id) {
    let res = ""

    copy.forEach((item) => {
      if (item.fields.Copy_ID === id) {
        res = item.fields.Copy
      }
    })

    return res
  }
}

export default Copy